var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("z-autocomplete", {
    attrs: {
      items: _vm.journeyList,
      hideDetails: true,
      label: "Jornadas",
      itemValue: "id",
      itemText: "name",
      "return-object": "",
    },
    on: {
      change: function ($event) {
        _vm.$emit("select-journey", _vm.journey)
        _vm.journey = ""
      },
    },
    model: {
      value: _vm.journey,
      callback: function ($$v) {
        _vm.journey = $$v
      },
      expression: "journey",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }